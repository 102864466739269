<template>
    <div class="cooperate">
        <div class="banner">
            <h2>诚邀加盟</h2>
            <h1>万亿市场 等你来启</h1>
        </div>
        <div class="title-box">
            <div class="title-main">
                <div class="title-item" v-for="(item, index) in titleList" :key="index">
                    <h2>{{ item.title }}</h2>
                    <span>{{ item.msg }}</span>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-box">
                <div class="code-box">
                    <div class="code">
                        <h3>加盟方式</h3>
                        <img :src="zmxCode" alt="">
                        <span>全国销售中心：朱经理/15968491606</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import imgsUrl from '../utils/imgAddress';

export default {
    data() {
        return {
            tabIndex: 0,
            bannerUrl: imgsUrl.bannerMd.cooperateBanner,
            codeBg: imgsUrl.bannerMd.codeBg,
            zmxCode: imgsUrl.code.zmxCode,
            tabForm: {
                imgUrl: '',
                list: []
            },
            titleList: [
                { title: '温情', msg: '把用户当作主角' },
                { title: '简奢', msg: '用极简的设计勾勒出最具灵性的空间' },
                { title: '极智', msg: '告别平庸，给您极智体验' },
            ],
            tabsList: ['展厅支持', '培训支持', '市场支持', '项目支持'],
            contentList: [
        {
          imgUrl: imgsUrl.fourSupport.ztzc, list: [
            { title: '快速建店', msg: '总部提供全方位协助，辅助加盟商快速建店；加盟合作伙伴还能享受LingLu标准展厅装修补贴，且提供统一的SI系统设计支持；同时，每一家加盟商都能享受标准展墙展示工具赠送待遇。' },
            { title: '年终补贴', msg: '完成年度任务享受年终高比例补贴；总部根据经销商的实际运营情况评选各类灵鹿奖，获奖者享受营销费用支持。' },
            { title: '活动补贴', msg: '公司每年将不定期举办预存货款赠送礼品的活动，详细请以活动通知为准。' },
          ]
        },
        {
          imgUrl: imgsUrl.fourSupport.pxzc, list: [
            { title: '培训学院', msg: '每季度开班一次经销商培训会，安排专业讲师授课，进行行业、产品、技术、销售、市场、案例等课程深度培训，提升经销商综合能力；不定时进行专业技能培训课程；' },
            { title: '一对一培训：', msg: '经销商可申请一对一针对性培训，经公司同意后由客户经理进行包括安装调试在内的专业一对一培训。' },
          ]
        }
        ,
        {
          imgUrl: imgsUrl.fourSupport.sczc, list: [
            { title: '宣传推广', msg: '户外广告牌、路灯灯箱广告、道路指示牌广告等补贴支持；经销商重大活动新媒体通讯稿宣传支持。' },
            { title: '营销支持', msg: '正式签约后，总部提供具有统一形象的授权牌、企业画册、宣传折页、手提袋、背包、文化衫、笔记本等在内价值3000元的营销大礼包一个。' },
            { title: '物料支持', msg: '提供公司手册，宣传手册，产品手册，方案手册，施工手册，新品推广单页等各类宣传物料若干份。' },
            { title: '活动推广', msg: '智能家居沙龙，设计师交流会活动人数超过50人，总部免费提供一人次现场支撑；本地家博会等展会总部提供宣传资料，易拉宝等宣传物料支持。小区地推活动总部提供宣传资料，易拉宝等宣传物料支持。' }
          ]
        }
        ,
        {
          imgUrl: imgsUrl.fourSupport.xmzc, list: [
            { title: '首个项目', msg: '签约经销商第一个项目享受1次免费现场实操指导及CAD详细方案服务，同时提供远程技术支持直至合作伙伴完全掌握全屋智能落地技能。第二个及以后的工程经销商可享受方案和报价指导。' },
            { title: '实时服务', msg: '提供7X24小时的人工服务，为经销商项目落地保驾护航。' },
          ]
        }
      ]

        }
    },
    mounted() {
        this.handleTabs(0)
    },
    methods: {
        handleTabs(index) {
            this.tabIndex = index;
            this.tabForm = this.contentList[index]
        }
    }
}
</script>
<style scoped lang="scss">
.cooperate {
    .banner {
        background-size: cover;
        background-position: top;
        background-position-x: center;
        background-position-y: top;
        width: 100%;
        height: 1200px;
        background-image: url('../assets/images/cooperate_banner.png');

        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h2 {
            font-size: 56px;
            color: #fff;
            letter-spacing: 20px;
            line-height: 84px;
            margin: 0;
            padding: 0;
            margin-bottom: 26px;
            margin-top: -200px;
            font-weight: 550;
            font-family: 'Medium';
        }

        h1 {
            margin: 0;
            padding: 0;
            font-size: 62px;
            color: #fff;
            letter-spacing: 20px;
            line-height: 84px;
            font-weight: 550;
            font-family: 'Medium';



        }

    }

    .title-box {
        margin-top: 100px;
        width: 100%;
        display: flex;
        justify-content: center;

        .title-main {
            width: 60%;
            display: flex;
            justify-content: space-around;
            background: #202020;
            border-radius: 8px;

            .title-item {
                color: #fff;
                text-align: center;
                border-right: 1px solid rgba($color: #FFFFFF, $alpha: .2);
                width: 33%;
                margin: 20px 0;

                h2 {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 500;
                    padding: 0;
                    margin: 0;
                    margin-bottom: 12px;
                    padding-top: 5px;

                }

                span {
                    color: #808080;
                    font-size: 14px;
                }

            }

            .title-item:last-child {
                border: none;
            }
        }
    }

    .main {
        display: flex;
        justify-content: center;
        margin-top: 200px;

        .main-box {
            width: 1400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;

            .title {
                font-size: 28px;
                font-weight: 400px;
                margin-bottom: 33px;
            }

            .tabs-box {
                width: 100%;
                display: flex;
                justify-content: center;
                cursor: pointer;

                .tab {
                    font-size: 24px;
                    display: flex;
                    align-items: center;

                    .tab-title {
                        padding: 0 53px;
                        color: #666666;
                    }

                    .tab-active {
                        color: #fff;
                    }

                    span {
                        display: block;
                        background: #BEBEBE;
                        height: 14px;
                        width: 2px;
                        display: block;
                    }

                }
            }

            .content-box {
                margin-top: 50px;
                width: 90%;

                .content {
                    min-height: 530px;
                    .content-item {
                        display: flex;
                        justify-content: space-between;

                        img {
                            width: 575px;
                            height: 386px;
                        }

                        ul {
                            padding-right: 57px;

                            li {
                                list-style-type: disc;
                                text-align: justify;

                                .li-title {
                                    font-size: 24px;
                                    font-weight: 500;
                                    line-height: 33px;
                                }

                                p {
                                    color: #808080;
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 30px;
                                }
                            }
                        }


                    }
                }
            }

            .code-box {
                width: 100%;
                height: 514px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../assets/images/code_bg.png');
                background-repeat: no-repeat; /* 防止图像重复 */
                background-size: cover; /* 拉伸或缩放图像以填充整个元素 */
                 background-position: center center; /* 图像在元素中居中对齐 */

                .code {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    

                    h3 {
                        font-size: 28px;
                        font-weight: 400;
                        line-height: 42px;
                        margin: 0;
                        padding: 0;
                    }

                    img {
                        width: 160px;
                        height: 160px;
                        margin: 40px 0;
                    }

                    span {
                        display: block;
                        font-size: 18px;
                    }
                }
            }
        }

    }

}
</style>